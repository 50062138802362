/* Manifest View, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="manifest-view" class="entity-view">

    <v-card-title>{{entity.Summary}}</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>
          <v-btn color="secondary"
                 class="me-3"
                 :to="{ name: 'manifest-list'}">
            <v-icon size="18"
                    class="me-1">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-row no-gutters>
      <v-col cols="12"
             sm="12"
             style="background-color:#e1e1e1">
        <div class="pa-5">
          <h4 class="mb-5">Manifest</h4>
          <v-card tile
                  class="mb-2"
                  v-for="(order) in entity.Orders"
                  :key="order.Id">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{order.Name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{order.Tracking}}
                </v-list-item-subtitle>
                <div class="mt-1 mb-1"
                     style="height:1px;width:100%; background-color:#e1e1e1"></div>
                <v-list-item-subtitle v-for="(item, itemIndex) in order.Items"
                                      :key="'i'+item.ItemId"
                                      style="font-size:80%">
                  Item {{itemIndex + 1}}: {{item.Length}}L x {{item.Width}}W x {{item.Height}}H
                </v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-card>
        </div>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClose
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.Manifest);
    const loading = ref(false);

    store.state.app.Manifest = null // otherwise you see old details before new ones loaded. I don't think we are using Vuex in the right manner here

    const fetchData = (id) => {
      store
        .dispatch('app/fetchManifest', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'manifest-list'});
        })
    }

    // and call now
    fetchData(route.value.params.id);


    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }

    return {
      loading,
      entity,
      Display,
      booleanIcons,
      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

